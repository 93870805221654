html,
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    display: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

.react-modal {
    z-index: 999;
}

a {
    color: black;
}

ul {
    list-style: none;
}

/* This is prevent zoom when focus input on mobile devices */
@media screen and (max-width: 767px) {
    input,
    select,
    textarea {
        font-size: 12px !important;
    }
}

a {
    text-decoration: none;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes slideBottom {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(100%);
    }
}
@keyframes zoomOpen {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes zoomClose {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes slideTop {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

.slide-left {
    animation: slideLeft 0.7s ease-in-out;
}

.slide-right {
    animation: slideRight 0.7s ease-in-out;
}

.slide-top {
    animation: slideTop 0.7s ease-in-out;
}

.slide-bottom {
    animation: slideBottom 0.7s ease-in-out;
}

.blinker-animation {
    animation: blinker 2s linear infinite;
}

.zoomOpen {
    animation: zoomOpen 0.7s ease-in-out;
}

.zoomClose {
    animation: zoomClose 0.7s ease-in-out;
}
