.keen-slider:not([data-keen-slider-disabled]){-webkit-touch-callout:none;-webkit-tap-highlight-color:transparent;align-content:flex-start;display:flex;overflow:hidden;position:relative;touch-action:pan-y;-webkit-user-select:none;-moz-user-select:none;user-select:none;-khtml-user-select:none;width:100%}.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide{min-height:100%;overflow:hidden;position:relative;width:100%}.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse]{flex-direction:row-reverse}.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v]{flex-wrap:wrap}
html,
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    display: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

.react-modal {
    z-index: 999;
}

a {
    color: black;
}

ul {
    list-style: none;
}

/* This is prevent zoom when focus input on mobile devices */
@media screen and (max-width: 767px) {
    input,
    select,
    textarea {
        font-size: 12px !important;
    }
}

a {
    text-decoration: none;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes slideBottom {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(100%);
    }
}
@keyframes zoomOpen {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes zoomClose {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes slideTop {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

.slide-left {
    animation: slideLeft 0.7s ease-in-out;
}

.slide-right {
    animation: slideRight 0.7s ease-in-out;
}

.slide-top {
    animation: slideTop 0.7s ease-in-out;
}

.slide-bottom {
    animation: slideBottom 0.7s ease-in-out;
}

.blinker-animation {
    animation: blinker 2s linear infinite;
}

.zoomOpen {
    animation: zoomOpen 0.7s ease-in-out;
}

.zoomClose {
    animation: zoomClose 0.7s ease-in-out;
}

.modalOverlay.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
    transform: translateX(100%);
}

.modalOverlay.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.modalOverlay.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(100%);
}
.addressModal.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
}

.addressModal.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
    transition: all 700ms ease-in-out;
}

.addressModal.ReactModal__Overlay--before-close {
    opacity: 0;
    transition: all 700ms ease-in-out;
}
.sortModal.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
}

.sortModal.ReactModal__Overlay--after-open {
    opacity: 1;
    transition: all 700ms ease-in-out;
}

.sortModal.ReactModal__Overlay--before-close {
    opacity: 0;
    transition: all 700ms ease-in-out;
}
.filterModal.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
    transform: translateX(100%);
}
.filterModal.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}
.filterModal.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(100%);
}
.cartModal.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
    transform: translateX(100%);
}

.cartModal.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.cartModal.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(100%);
}
.cartSummaryModal.ReactModal__Overlay {
    transition: all 700ms ease-in-out;
}

.cartSummaryModal.ReactModal__Overlay--after-open {
    transition: all 700ms ease-in-out;
}
.cartSummaryModal.ReactModal__Overlay--after-close {
    transition: all 700ms ease-in-out;
}
.leftMenuModalOverlay.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
    transform: translateX(-100%);
}

.leftMenuModalOverlay.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.leftMenuModalOverlay.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100%);
}
.zoomModal.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
}

.zoomModal.ReactModal__Overlay--after-open {
    opacity: 1;
    transition: all 700ms ease-in-out;
}

.zoomModal.ReactModal__Overlay--before-close {
    opacity: 0;
    transition: all 700ms ease-in-out;
}
@media screen and (max-width: 1024px) {
    .sortModal.ReactModal__Overlay {
        opacity: 0;
        transition: all 500ms ease-in-out;
        transform: translateY(100%);
    }

    .sortModal.ReactModal__Overlay--after-open {
        opacity: 1;
        transform: translateY(0);
    }

    .sortModal.ReactModal__Overlay--before-close {
        opacity: 0;
        transform: translateY(100%);
    }
}

@font-face {
font-family: '__futura_1271bb';
src: url(/_next/static/media/2ad65b50f19d3c1d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__futura_1271bb';
src: url(/_next/static/media/37890a80ee08f949-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__futura_1271bb';
src: url(/_next/static/media/2cb03dcc1b7999c1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__futura_1271bb';
src: url(/_next/static/media/2e8e89347db905ae-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}@font-face {font-family: '__futura_Fallback_1271bb';src: local("Arial");ascent-override: 84.26%;descent-override: 27.74%;line-gap-override: 20.55%;size-adjust: 97.32%
}.__className_1271bb {font-family: '__futura_1271bb', '__futura_Fallback_1271bb'
}

